var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-card",
            {
              staticClass: "background-color",
              attrs: { title: "Default Colors" }
            },
            [
              _c("b-alert", { attrs: { variant: "primary", show: "" } }, [
                _c(
                  "div",
                  { staticClass: "alert-body" },
                  [
                    _vm._v(" For more color classes options please read the "),
                    _c(
                      "b-link",
                      {
                        staticClass: "alert-link",
                        attrs: {
                          href:
                            "https://pixinvent.com/demo/vuexy-html-bootstrap-admin-template/documentation/documentation-colors.html",
                          target: "_blank"
                        }
                      },
                      [_vm._v(" documentation. ")]
                    )
                  ],
                  1
                )
              ]),
              _c("b-card-text", { staticClass: "mb-0" }, [
                _vm._v(
                  " We have a series of colors that are used by default. They include: "
                )
              ]),
              _c("ul", { staticClass: "colors-list list-unstyled mb-0" }, [
                _c(
                  "li",
                  [
                    _c("feather-icon", {
                      attrs: { size: "15", icon: "ChevronsRightIcon" }
                    }),
                    _c("span", [_vm._v("Primary")])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("feather-icon", {
                      attrs: { size: "15", icon: "ChevronsRightIcon" }
                    }),
                    _c("span", [_vm._v("Secondary")])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("feather-icon", {
                      attrs: { size: "15", icon: "ChevronsRightIcon" }
                    }),
                    _c("span", [_vm._v("Success")])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("feather-icon", {
                      attrs: { size: "15", icon: "ChevronsRightIcon" }
                    }),
                    _c("span", [_vm._v("Warning")])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("feather-icon", {
                      attrs: { size: "15", icon: "ChevronsRightIcon" }
                    }),
                    _c("span", [_vm._v("Danger")])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("feather-icon", {
                      attrs: { size: "15", icon: "ChevronsRightIcon" }
                    }),
                    _c("span", [_vm._v("Info")])
                  ],
                  1
                )
              ]),
              _c("b-card-text", [
                _vm._v(
                  " For each color, we think has its functionality in the application as alerts of errors "
                ),
                _c("b", [_vm._v("(danger)")]),
                _vm._v(", warnings to the user "),
                _c("b", [_vm._v("(warning)")]),
                _vm._v(", communications of approval "),
                _c("b", [_vm._v("(success)")]),
                _vm._v(", or main color of the application "),
                _c("b", [_vm._v("(primary)")]),
                _vm._v(". ")
              ]),
              _c(
                "div",
                { staticClass: "d-flex justify-content-start flex-wrap" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-primary colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("primary")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-secondary colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("secondary")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-success colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("success")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-warning colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("warning")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-danger colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("danger")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-info colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center my-1 ml-50 shadow"
                    },
                    [_c("span", [_vm._v("info")])]
                  )
                ]
              ),
              _c("h6", { staticClass: "mt-1" }, [_vm._v(" Lighten Variant ")]),
              _c("b-card-text", { staticClass: "mb-0" }, [
                _vm._v(" Use "),
                _c("code", [
                  _vm._v(".bg-{color}.bg-lighten-{1 | 2 | 3 | 4 | 5}")
                ]),
                _vm._v(" classes for lighten background color. ")
              ]),
              _c(
                "div",
                { staticClass: "d-flex justify-content-start flex-wrap" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-primary bg-lighten-2 colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("lighten-2")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-secondary bg-lighten-2 colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("lighten-2")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-success bg-lighten-2 colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("lighten-2")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-warning bg-lighten-2 colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("lighten-2")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-danger bg-lighten-2 colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("lighten-2")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-info bg-lighten-2 colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center my-1 ml-50 shadow"
                    },
                    [_c("span", [_vm._v("lighten-2")])]
                  )
                ]
              ),
              _c("h6", { staticClass: "mt-1" }, [_vm._v(" Darken Variant ")]),
              _c("b-card-text", { staticClass: "mb-0" }, [
                _vm._v(" Use "),
                _c("code", [_vm._v(".bg-{color}.bg-darken-{1 | 2 | 3 | 4}")]),
                _vm._v(" classes for darken background color. ")
              ]),
              _c(
                "div",
                { staticClass: "d-flex justify-content-start flex-wrap" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-primary bg-darken-2 colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("darken-2")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-secondary bg-darken-2 colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("darken-2")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-success bg-darken-2 colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("darken-2")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-warning bg-darken-2 colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("darken-2")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-danger bg-darken-2 colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("darken-2")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-info bg-darken-2 colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center my-1 ml-50 shadow"
                    },
                    [_c("span", [_vm._v("darken-2")])]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-card",
            { attrs: { title: "Gradient Colors" } },
            [
              _c("b-card-text", { staticClass: "mb-0" }, [
                _vm._v(
                  " We have make gradient color based on our default color: "
                )
              ]),
              _c("ul", { staticClass: "colors-list list-unstyled mb-0" }, [
                _c(
                  "li",
                  [
                    _c("feather-icon", {
                      attrs: { size: "15", icon: "ChevronsRightIcon" }
                    }),
                    _c("span", [_vm._v("bg-gradient-primary")])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("feather-icon", {
                      attrs: { size: "15", icon: "ChevronsRightIcon" }
                    }),
                    _c("span", [_vm._v("bg-gradient-secondary")])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("feather-icon", {
                      attrs: { size: "15", icon: "ChevronsRightIcon" }
                    }),
                    _c("span", [_vm._v("bg-gradient-success")])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("feather-icon", {
                      attrs: { size: "15", icon: "ChevronsRightIcon" }
                    }),
                    _c("span", [_vm._v("bg-gradient-warning")])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("feather-icon", {
                      attrs: { size: "15", icon: "ChevronsRightIcon" }
                    }),
                    _c("span", [_vm._v("bg-gradient-danger")])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("feather-icon", {
                      attrs: { size: "15", icon: "ChevronsRightIcon" }
                    }),
                    _c("span", [_vm._v("bg-gradient-info")])
                  ],
                  1
                )
              ]),
              _c("b-card-text", [
                _vm._v(
                  " For each color, we think has its functionality in the application as alerts of errors "
                ),
                _c("b", [_vm._v("(danger)")]),
                _vm._v(", warnings to the user "),
                _c("b", [_vm._v("(warning)")]),
                _vm._v(", communications of approval "),
                _c("b", [_vm._v("(success)")]),
                _vm._v(", or main color of the application "),
                _c("b", [_vm._v("(primary)")]),
                _vm._v(". ")
              ]),
              _c(
                "div",
                { staticClass: "d-flex justify-content-start flex-wrap" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center colors-container bg-gradient-primary rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("primary gradient")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center colors-container bg-gradient-secondary rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("secondary gradient")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center colors-container bg-gradient-success rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("success gradient")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center colors-container bg-gradient-warning rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("warning gradient")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center colors-container bg-gradient-danger rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
                    },
                    [_c("span", [_vm._v("danger gradient")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center colors-container bg-gradient-info rounded text-white width-100 height-100 d-flex align-items-center justify-content-center my-1 ml-50 shadow px-1"
                    },
                    [_c("span", [_vm._v("info gradient")])]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }